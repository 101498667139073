<template>
  <div id="welcome">
    <div class="card bg-dark text-white border-0 mb-4">
      <!-- <img src="https://www.expofranquicias.cl/img/franquicias/pethappy.jpg" class="card-img" alt="..." /> -->
      <div class="card-img-overlay">
        <h5 class="card-title font-weight-bold"></h5>
        <p class="card-text"></p>
      </div>
    </div>

    <!-- <a
      href="https://www.pethappy.cl/destacados/verano"
      target="_blank"
      class="card bg-dark text-white mb-4"
    >
      <img
        src="https://assets.jumpseller.com/store/pethappy/themes/25289/options/6015073/DESTACADOS-VERANO-BASE.gif?1610485325"
        class="card-img"
        alt="..."
      />
      <div class="card-img-overlay">
        <h5 class="card-title"></h5>
        <p class="card-text"></p>
      </div>
    </a> -->

    <h4 class="text-center">Atención veterinaria con disponibilidad completa de lunes a domingo en todas nuestras sucursales!</h4>
    <h4 class="text-center">¡Trae a tu mascota, te esperamos! O si prefieres, selecciona la opción para agendar tu cita veterinaria</h4>
    <p class="text-muted text-center">Selecciona el tipo de cita que deseas agendar.</p>

    <div class="row justify-content-md-center">
      <div class="col-md-4"  @click="goTo('/atencion-veterinaria')" >
        <div class="card option shadow-sm check-ava">
          <div class="card-body text-center">
            <button  @click="goTo('/atencion-veterinaria')"  class="btn btn-success btn-icon">
              <font-awesome-icon icon="user-md" />
            </button>

            <h5 class="mt-4 option-service">Atención veterinaria general</h5>
          </div>
        </div>
      </div>
      <div class="col-md-4"  @click="goTo('/especialidad-atencion')" >
        <div class="card option shadow-sm check-ava">
          <div class="card-body text-center">
            <button  @click="goTo('/especialidad-atencion')"  class="btn btn-success btn-icon">
              <font-awesome-icon icon="user-md" />
            </button>

            <h5 class="mt-4 option-service">Atención veterinaria por especialidad</h5>
          </div>
        </div>
      </div>
<!--
      <div class="col-md-4">
        <div class="card option shadow-sm">
          <div class="card-body text-center">
            <button class="btn btn-success btn-icon">
              <font-awesome-icon icon="dog" />
            </button>

            <h5 class="mt-4 option-service">Servicio de peluqueria</h5>
          </div>
        </div>
      </div> -->

      <div class="col-md-4" @click="goTo('/cancelar-cita')">
        <div class="card option shadow-sm">
          <div class="card-body text-center">
            <button @click="goTo('/cancelar-cita')" class="btn btn-success btn-icon">
              <font-awesome-icon icon="store-alt-slash" />
            </button>

            <h5 class="mt-4 option-service">Anular hora tomada</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(url) {
      this.$store.commit("resetAllData");
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.bg-dark {
  background-color: #343a40 !important;
}
.text-center {
  text-align: center !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.text-muted {
  color: #6c757d !important;
}
.justify-content-md-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.text-white {
  color: white;
}
.option {
  transition: all 0.1s;
  color: rgb(99, 99, 99);
  cursor: pointer;
  opacity: 0.8;
  margin-bottom: 20px;
}
.option-service{
  font-size: 1.1rem;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.el-col {
  padding-right: 15px;
  padding-left: 15px;
}
.btn-icon {
  background: #34a853;
  color: white;
  font-size: 30px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-color: #28a745;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
h4 {
  font-size: 1.5rem;
}
.option:hover {
  transform: scale(1.05);
  color: rgb(26, 26, 26);
  opacity: 1;
  z-index: 999999;
}
#welcome {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.text-option {
  font-size: 1.4rem;
  margin-top: 1.5rem !important;
}
</style>
